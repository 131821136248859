import { useContext, useEffect, useState } from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { appContext } from '../../context'
import Body from '../../components/Body'
import Header from '../../components/Header'
import usePayments from '../../hooks/usePayments'
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement'
import URL from '../../constants/route-urls'
import PaymentMethod from '../../components/PaymentMethods'
import paymentMethodEnvironmentVariables from '../../constants/payment-method-env-var-values'
import pageStyle from '../PageContent.module.scss'
import styles from './Payment.module.scss'
import '@adyen/adyen-web/dist/adyen.css'
import Core from '@adyen/adyen-web/dist/types/core/core'
import translations from './adyenTranslations.json'
import PaymentMethodTrigger from '../../components/PaymentMethods/PaymentMethodTrigger'
import { PaymentApplePay, PaymentCard, PaymentGooglePay, PaymentKlarna, PaymentPaypal } from '../../components/icons'
import { FullPageLoader } from '../../components/Loader'
import AcceptedCards from '../../components/AcceptedCards/AcceptedCards'
import { useScript } from 'usehooks-ts'
import useDataLayer from '../../hooks/useDataLayer'
import { paymentTypeData, nonAdyenPaymentTypeData } from '../../utils/getPaymentType'
import { IPromise, TypeActiveMethod } from './types'
import NotificationCard from '../../components/NotificationCard'
import { getHomeGym } from '../../utils/getUserData'
import useGetBasket from '../../hooks/useGetBasket'

const Payment = () => {
  const { createPayment, getPayment } = usePayments()
  const context = useContext(appContext)
  const [adyenCheckout, setAdyenCheckout] = useState<Core>()
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false) // Block ApplePay option if it's not available
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false) // Block GooglePay option if it's not available
  const [paymentServiceError, setPaymentServiceError] = useState<string | null>()
  const [adyenPaymentError, setAdyenPaymentError] = useState<string | null>()
  const [adyenComponentError, setAdyenComponentError] = useState<string | null>()
  const [activeMethod, setActiveMethod] = useState<TypeActiveMethod>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { checkoutEvent, dataLoadedEvent, getCartItemsForDataLayer } = useDataLayer()
  const { getBasketById } = useGetBasket()
  const [basketToPay, setBasketToPay] = useState<BasketType>()
  const [paymentId, setPaymentId] = useState<string | null>()

  const returnUrlSessionIdParam = searchParams.get('sessionId')
  const returnUrlSessionDataParam = searchParams.get('redirectResult')
  const isRedirectPage = returnUrlSessionIdParam && returnUrlSessionDataParam

  const loginURL = `${process.env.REACT_APP_LOGIN_URL}?returnPath=${window.location.href}`

  // Clear returnUrl params
  const resetUrlParams = () => {
    if (searchParams.has('sessionId')) {
      searchParams.delete('sessionId')
    }

    if (searchParams.has('redirectResult')) {
      searchParams.delete('redirectResult')
    }

    setSearchParams(searchParams)
  }

  // Add Google Pay script in <head>
  const gPayStatus = useScript('https://pay.google.com/gp/p/js/pay.js', {
    removeOnUnmount: false,
    id: 'google-pay-script',
  })

  // Build sentance for non-card payment methods
  const buildNonCardPaymentMethodsSentence = (enabledPaymentMethods: string[]) => {
    // Remove CARD and create array of user friendly strings for each payment method
    const noCardPaymentMethods = enabledPaymentMethods.filter((method) => method !== 'CARD').map((method) => {
      let methodDisplayString

      switch (method) {
      case 'PAYPAL':
        methodDisplayString = 'PayPal'
        break
      case 'GOOGLEPAY':
        methodDisplayString = 'Google Pay'
        break
      case 'APPLEPAY':
        methodDisplayString = 'Apple Pay'
        break
      case 'KLARNA':
        methodDisplayString = 'Klarna'
        break
      default:
        methodDisplayString = ''
      }

      return methodDisplayString
    })

    // If only 1 payment method, return the sentence here.
    if (noCardPaymentMethods.length === 1) {
      return `Paying with ${noCardPaymentMethods.toString()} will open a new window.`
    }

    // insert 'or' into array - second from last position
    const orStrInsertPosition = noCardPaymentMethods.length - 1
    noCardPaymentMethods.splice(orStrInsertPosition, 0, 'or')

    // Format the 'or' text correctly
    const paymentMethodsForSentence = noCardPaymentMethods.join(', ').replace(', or,', ' or')  

    return `Paying with ${paymentMethodsForSentence} will open a new window.`
  }

  // Use env var to determine which payment methods are displayed in the UI
  let paymentsEnabled: string[] = []
  let paymentMethodsSentence = ''
  if (process.env.REACT_APP_ADYEN_PAYMENT_COMPONENTS) {
    paymentsEnabled = JSON.parse(process.env.REACT_APP_ADYEN_PAYMENT_COMPONENTS)
    paymentMethodsSentence = buildNonCardPaymentMethodsSentence(paymentsEnabled)
  }

  // `some` loop for the UI to check if the payment method ID can be shown based on REACT_APP_ADYEN_PAYMENT_COMPONENTS
  const isPaymentMethodEnabled = (paymentMethodID: string) => paymentsEnabled.some(method => method === paymentMethodID)

  const onPaymentCompleted = (result: any, component: UIElement) => {
    setAdyenPaymentError(null)

    if (result.resultCode === 'Authorised') {
      // Get payment type data
      if (component) {
        window.adyenPaymentType = paymentTypeData(component)
      } else {
        window.adyenPaymentType = nonAdyenPaymentTypeData(context?.persistentPayMethod)
      }

      // If all is well move on to order confirmation page
      setIsLoading(false)

      // CLEAR CART ID LOCAL STORAGE HERE
      context?.setBasketIdForPay(null)
      navigate(`/${URL.ORDER_CONFIRMATION}`)
    } else {
      setAdyenPaymentError(result.resultCode)
      setIsLoading(false)

      // Clear returnUrl params to allow user to reset and retry
      // (the params are no longer needed at this point)
      resetUrlParams()
    }
  }

  const setupPayments = async (skipCreatePayment?: boolean) => {
    setIsLoading(true)

    // Check for existing payments
    const existingPayment = await getPayment(basketToPay)
    setPaymentId(existingPayment?.paymentId)
    const transaction = existingPayment?.transactions?.[0]

    // If the user has a payment in their basket and it's complete
    if (transaction?.state === 'SUCCESS') {
      // Store payment type for order confirmation
      window.adyenPaymentType = nonAdyenPaymentTypeData(existingPayment?.paymentType) 
      setIsLoading(false)

      // Go to order confirmation page
      navigate(`/${URL.ORDER_CONFIRMATION}`)
      return
    }

    let adyenSession

    if (!skipCreatePayment) {
      adyenSession = await createPayment(basketToPay) // Create session

      // If error occurs on session creation/before web components created.
      if (!adyenSession || adyenSession.name === 'AxiosError') {
        setIsLoading(false)

        if (adyenSession.errorCode === '14_0422') {
          setPaymentServiceError('14_0422')
        } else {
          setPaymentServiceError(adyenSession.message ?? 'An error occurred when setting up your payment session.')
        }
      }
    }

    const configuration = {
      environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      analytics: {
        enabled: true,
      },
      session: {
        id: adyenSession?.sessionDetails?.id,
        sessionData: adyenSession?.sessionDetails?.sessionData,
      },
      beforeSubmit: (data: any, component: UIElement, actions: IPromise): Promise<void> => {
        setIsLoading(true)
        return actions.resolve(data)
      },
      onActionHandled: () => {
        // If additional step (such as 3DS) is loaded, stop loader.
        setIsLoading(false)
      },
      onPaymentCompleted,
      showPayButton: true,
      onError: (error: any, component: any) => {
        // Errors that occur on submission handled here
        setIsLoading(false)

        // If error returned is 14_0422 on submission...
        // We cannot read the error code, only the message. The code is not surfaced here so we cannot match against it. 
        if (error.message === 'The provided session identifier or data is invalid.') {
          setAdyenComponentError('14_0422')
        } else {
          setAdyenComponentError(`${error.name}: ${error.message}`)
        }

        console.error(error.name, error.message, error.stack, component)
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: true,
        },
      },
      locale: 'en-GB',
      // Reference to customisable text: https://github.com/Adyen/adyen-web/blob/c6c19ac646a8a211d0416a462b2c2a2a722eff29/packages/lib/src/language/locales/en-US.json
      translations,
    }

    // If the user has returned from a redirect method (Klarna)
    if (returnUrlSessionIdParam) {
      configuration.session.id = returnUrlSessionIdParam
      configuration.session.sessionData = undefined
    }

    // Access the available payment methods for the session.
    try {
      const checkout = await AdyenCheckout(configuration)
      setAdyenCheckout(checkout)

      // Check if Apple Pay is available
      const applePayComponent = checkout.create('applepay')
      applePayComponent.isAvailable()
        .then(() => {
          setIsApplePayAvailable(true)
        })
        .catch(e => {
          //Apple Pay is not available
          console.error(e)
          setIsApplePayAvailable(false)
        })

      // Check if Google Pay is available
      const googlePayComponent = checkout.create('googlepay')
      googlePayComponent.isAvailable()
        .then(() => {
          setIsGooglePayAvailable(true)
        })
        .catch(e => {
          //google is not available
          console.error(e)
          setIsGooglePayAvailable(false)
        })

      if (returnUrlSessionIdParam && returnUrlSessionDataParam) {
        // If returnUrl params exist, submit payment and stop here
        checkout.submitDetails({
          details: {
            redirectResult: returnUrlSessionDataParam,
          },
        })

        return
      }
    } catch (error) {
      console.error(error)
    }

    setIsLoading(false)
  }

  const handlePaymentSelection = (type: TypeActiveMethod) => {
    setAdyenPaymentError(null)
    setActiveMethod(type)
    context?.setPersistentPayMethod(type)

    checkoutEvent({
      'action': 'checkout',
      'step': 2,
      'option': type ?? '',
    })
  }

  const resetPaymentMethods = () => {
    // Reset error state
    setActiveMethod(null)
    setPaymentServiceError(null)
    setAdyenPaymentError(null)
    setAdyenComponentError(null)

    // Set up payment session again
    if (isRedirectPage) {
      resetUrlParams()
    }

    // Re-run payments POST to set up session again
    setupPayments()
  }

  useEffect(() => {
    // Create session only after we have the basket data
    if (!context?.basket || isLoading) {
      return
    }

    // If cart is empty send user back to empty cart page which shows a message
    if (!context?.basket?.commerceItems[0]) {
      navigate(`/${URL.CART}`)
      return
    }

    (async () => {
      // If there is a basket ID stored in local storage...
      if (context?.basketIdForPay) {
        setIsLoading(true)

        // ...get cart by ID
        const basketByIdData = await getBasketById(context?.basketIdForPay)
        setBasketToPay(basketByIdData?.data)
        setIsLoading(false)
      } else {
        setBasketToPay(context?.basket)
      }

      // Set up payment session
      if (isRedirectPage) {
        setupPayments(true)
        return
      }

      setupPayments()

      // Datalayer events
      dataLoadedEvent({
        pageType: 'Payment',
        pageName: 'Payment',
        pageUrl: `${window.location.origin}/${URL.PAY_BY_CARD}`,
        pageTitle: 'Payment',
      },
      {
        action: 'checkout',
        step: 2,
        coupon: '',
        couponType: '',
      },
      getCartItemsForDataLayer(),
      )
    })()

  }, [context?.basket])

  useEffect(() => {
    window.adyenPaymentType = null

    // In this case no gym previously selected we use the home gym.
    if (!context?.gymId || context?.gymId === '') {
      context?.setGymId(getHomeGym())
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Payment</title>
      </Helmet>
      <Header
        title="Payment"
        step={4}
      />
      <Body>
        {(paymentServiceError === 'Request failed with status code 401' || (context?.basketError && context?.basketError.status === 401))  && 
          <NotificationCard
            heading="Your session has expired due to inactivity."
            type='danger'
          >
            <div>
              <p>Please log in to continue buying personal training. Don’t worry, your basket items have been saved.</p>
            </div>
            <div>
              <p><a href={loginURL}>Login</a></p>
            </div>
          </NotificationCard>
        }
        {context?.basketError && context?.basketError.status !== 401 &&
          <NotificationCard
            type='danger'
            heading='Sorry, your basket could not be retrieved'
          >
            Please try again later or contact your local gym to book personal training.
          </NotificationCard>
        }
        {basketToPay?.commerceItems[0] &&
          <>
            <section className={pageStyle.pageSection} aria-label='Personal trainer package summary'>
              <h2>Order information</h2>
              <div className={styles.orderInfoSection}>
                <h4>Personal trainer</h4>
                <p data-testid='session-pt-name'>{basketToPay?.commerceItems[0].sessionPackage?.personalTrainer} (requested)</p>
              </div>
              <div className={styles.orderInfoSection}>
                <h4>Package</h4>
                <p data-testid='session-pt-size'>{basketToPay?.commerceItems[0].sessionPackage?.size} hours</p>
              </div>
              <div className={styles.orderInfoSection}>
                <h4>Location</h4>
                <p data-testid='session-pt-location'>{basketToPay?.commerceItems[0].sessionPackage?.location}</p>
              </div>
              <div className={styles.orderInfoSection}>
                <h4>Price</h4>
                <p data-testid='session-pt-price'>{context?.currencySymbol}{basketToPay?.price.discountedPrice?.toFixed(2) ?? basketToPay?.price.totalPrice?.toFixed(2)}</p>
              </div>
            </section>

            <hr className={styles.hr} />

            <section className={`${pageStyle.pageSection} ${styles.paymentSection}`} aria-label='Payment section'>
              <h2>Payment method</h2>
              {(
                paymentServiceError ||
                adyenComponentError ||
                adyenPaymentError
              ) &&
                <div className={styles.paymentError}>
                  {(paymentServiceError === '14_0422' || adyenComponentError === '14_0422') &&
                    <div>
                      <p><strong>Please wait while we process your payment.</strong></p>
                      <p>This can take up to 10 minutes, so please check your email for confirmation before refreshing the page.</p>
                      {paymentId &&
                        <p>Payment ID: {paymentId}</p>
                      }
                      <p>If you do not receive a confirmation email, please contact the gym and provide your payment ID. Our technical team will look into the issue and respond within 48 hours.</p>
                    </div>
                  }

                  {paymentServiceError !== '14_0422' && <p>{paymentServiceError}</p>}
                  {adyenComponentError !== '14_0422' && <p>{adyenComponentError}</p>}
                  {adyenPaymentError}

                  <div className={styles.paymentErrorBtnWrapper}>
                    <button className={styles.paymentErrorBtn} onClick={resetPaymentMethods}>Retry</button>
                  </div>
                </div>
              }

              {adyenCheckout &&
                <div className={styles.paymentContainers}>
                  {isPaymentMethodEnabled(paymentMethodEnvironmentVariables.card) &&
                    <>
                      <div>We accept the following card payments: <AcceptedCards /></div>
                      <div className={styles.paymentBox}>
                        <PaymentMethodTrigger
                          methodName='Credit or debit card'
                          onClick={() => { handlePaymentSelection('card') }}
                          isActive={activeMethod === 'card'}
                          icon={<PaymentCard width={40} height={26} />}
                        />

                        {activeMethod === 'card' &&
                          <div className={styles.paymentMethodWrapper}>
                            <PaymentMethod
                              paymentMethod='card'
                              adyenCheckout={adyenCheckout}
                              error={adyenPaymentError}
                            />
                          </div>
                        }
                      </div>
                    </>
                  }

                  <div className={styles.paymentSeperator}>
                    <div className={styles.seperatorLine}></div>
                    <div className={styles.seperatorText}>Or</div>
                    <div className={styles.seperatorLine}></div>
                  </div>

                  <p>{paymentMethodsSentence}</p>

                  {isPaymentMethodEnabled(paymentMethodEnvironmentVariables.payPal) &&
                    <div className={styles.paymentBox}>
                      <PaymentMethodTrigger
                        methodName='PayPal'
                        onClick={() => { handlePaymentSelection('paypal') }}
                        isActive={activeMethod === 'paypal'}
                        icon={<PaymentPaypal width={40} height={26} />}
                      />
                      {activeMethod === 'paypal' &&
                        <div className={styles.paymentMethodWrapper}>
                          <PaymentMethod
                            paymentMethod='paypal'
                            adyenCheckout={adyenCheckout}
                            error={adyenPaymentError}
                          />
                        </div>
                      }
                    </div>
                  }

                  {isPaymentMethodEnabled(paymentMethodEnvironmentVariables.googlePay) && gPayStatus && isGooglePayAvailable &&
                    <div className={styles.paymentBox}>
                      <PaymentMethodTrigger
                        methodName='Google Pay'
                        onClick={() => { handlePaymentSelection('googlepay') }}
                        isActive={activeMethod === 'googlepay'}
                        icon={<div className={styles.googlePayWrapper}><PaymentGooglePay width={30} height={18} /></div>}
                      />
                      {activeMethod === 'googlepay' &&
                        <div className={styles.paymentMethodWrapper}>
                          <PaymentMethod
                            paymentMethod='googlepay'
                            adyenCheckout={adyenCheckout}
                            error={adyenPaymentError}
                          />
                        </div>
                      }
                    </div>
                  }

                  {isPaymentMethodEnabled(paymentMethodEnvironmentVariables.applePay) && isApplePayAvailable &&
                    <div className={styles.paymentBox}>
                      <PaymentMethodTrigger
                        methodName='Apple Pay'
                        onClick={() => { handlePaymentSelection('applepay') }}
                        isActive={activeMethod === 'applepay'}
                        icon={<PaymentApplePay width={40} height={26} />}
                      />
                      {activeMethod === 'applepay' &&
                        <div className={styles.paymentMethodWrapper}>
                          <PaymentMethod
                            paymentMethod='applepay'
                            adyenCheckout={adyenCheckout}
                            error={adyenPaymentError}
                          />
                        </div>
                      }
                    </div>
                  }

                  {isPaymentMethodEnabled(paymentMethodEnvironmentVariables.klarna) &&
                    <div className={styles.paymentBox}>
                      <PaymentMethodTrigger
                        methodName='Pay over time with Klarna.'
                        onClick={() => { handlePaymentSelection('klarna') }}
                        isActive={activeMethod === 'klarna'}
                        icon={<PaymentKlarna width={40} height={26} />}
                      />
                      {activeMethod === 'klarna' &&
                        <div className={styles.paymentMethodWrapper}>
                          <PaymentMethod
                            paymentMethod='klarna_account'
                            adyenCheckout={adyenCheckout}
                            error={adyenPaymentError}
                          />
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </section>
          </>
        }
      </Body>

      <FullPageLoader isLoading={isLoading} setIsLoading={setIsLoading} />
    </>
  )
}

export default Payment